/* eslint-disable max-lines */
import {
	FETCH_SUBSCRIPTION, UPDATE_SUBSCRIPTION, UPDATE_SHIPPING_ADDRESS, CHANGE_ORDER_STATUS, CLEAR_SUBSCRIPTION_CART,
	TOGGLE_SUBS_CART, LIST_SCHEDULED_ORDERS, TOGGLE_UPDATING_SUB_PRODUCT, TOGGLE_RESCHEDULE_MODAL,
	LIST_PAYMENT_METHODS, CHANGE_PAYMENT_METHOD, LOGOUT, TOGGLE_SCHEDULED_ORDERS_MODAL,
	EDIT_LINE_ITEM, REMOVE_LINE_ITEM, CREATE_LINE_ITEM, UPDATE_LINE_TO_CREATE, TOGGLE_UNAVAILABLE_ITEMS,
	TOGGLE_STICKY_CART, TOGGLE_SAVE_LOADING, CHANGE_NEXT_BILLING_DATE, UPDATE_DELIVERY_FREQUENCY,
	UPDATE_DELIVERY_PRICE, UPDATE_SUBSCRIPTION_BOX
} from "utils/action-types";

const initialState = {
	subscriptionId: null,
	discounts: [],
	totalLineItemPrice: 0,
	totalQuantity: 0,
	totalLineItemDiscountedPrice: 0,
	deliveryPrice: 0,
	status: "",
	nextBillingDate: null,
	nextBillingDateEpoch: null,
	paymentMethodId: null,
	deliveryPolicy: {},
	shippingAddress: null,
	lines: {},
	paymentMethods: [],
	isActive: false,
	isCartOpen: false,
	scheduledOrders: {},
	isScheduledOrdersModalOpen: false,
	isRescheduleModalOpen: false,
	linesToUpdate: {},
	linesToRemove: {},
	linesToCreate: {},
	pendingChanges: false,
	isFetched: false,
	unavailable_items: [],
	isStickyCartOpen: false,
	saveInProgress: false,
	activeBox: {
		userSelected: false,
	},
	chargeOffsetDays: 0,
	commitmentBasedDiscount: null,
	removeCount: 0,
};

const calculateTotalQuantity = (lineItems) => (
	Object.values(lineItems).reduce((acc, item) =>
		acc + (item.isDuplicated ? item.totalQuantity : item.quantity) * (item.weightInGrams >= 1000 ?
			item.weightInGrams / 1000 :
			item.weightInGrams <= 1 ? 0 : item.weightInGrams), 0)
);

const hasPendingChanges = (linesToCreate, linesToUpdate, linesToRemove) => (
	Object.keys(linesToCreate).length > 0 || Object.keys(linesToUpdate).length > 0 || Object.keys(linesToRemove).length > 0
);

const subscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SUBSCRIPTION:
			return {
				...state,
				...action.payload,
				linesToCreate: {},
				linesToUpdate: {},
				linesToRemove: {},
				pendingChanges: false,
				isFetched: true,
				cartHasBeenCleared: false,
				saveInProgress: false,
				removeCount: 0
			};

		case UPDATE_SUBSCRIPTION:
			return {
				...state,
				...action.payload,
				isActive: action.payload.status === "ACTIVE",
			};

		case UPDATE_SUBSCRIPTION_BOX:
			return {
				...state,
				activeBox: {
					...state.box,
					...action.payload,
				}
			};

		case CHANGE_NEXT_BILLING_DATE:
			return {
				...state,
				nextBillingDate: action.payload.nextBillingDate,
				nextBillingDateEpoch: action.payload.nextBillingDateEpoch,
			};

		case CHANGE_ORDER_STATUS: {
			const scheduledOrders = {
				...state.scheduledOrders,
				[action.payload.orderId]: {
					...state.scheduledOrders[action.payload.orderId],
					status: action.payload.status
				},
			};
			return {
				...state,
				scheduledOrders,
			};
		}

		case UPDATE_DELIVERY_FREQUENCY:
			return {
				...state,
				deliveryPolicy: action.payload,
			};

		case LIST_SCHEDULED_ORDERS:
			return {
				...state,
				scheduledOrders: Object.fromEntries(action.payload.orders.map(order => [order.id, order])),
			};

		case UPDATE_DELIVERY_PRICE:
			return {
				...state,
				deliveryPrice: action.payload,
			};

		case UPDATE_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: action.payload
			};

		case LIST_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.payload
			};

		case CHANGE_PAYMENT_METHOD:
			return {
				...state,
				paymentMethodId: action.payload
			};

		case TOGGLE_SUBS_CART:
			return {
				...state,
				isCartOpen: !state.isCartOpen
			};

		case TOGGLE_SCHEDULED_ORDERS_MODAL:
			return {
				...state,
				isScheduledOrdersModalOpen: !state.isScheduledOrdersModalOpen
			};

		case TOGGLE_RESCHEDULE_MODAL:
			return {
				...state,
				isRescheduleModalOpen: !state.isRescheduleModalOpen
			};

		case TOGGLE_UPDATING_SUB_PRODUCT:
			return {
				...state,
				lines: {
					...state.lines,
					[action.productVariantId]: {
						...state.lines[action.productVariantId],
						updating: !state.lines[action.productVariantId]?.updating,
						stock: action.stock,
					}
				}
			};

		case TOGGLE_UNAVAILABLE_ITEMS:
			return {
				...state,
				unavailable_items: action.payload
			};

		case TOGGLE_STICKY_CART: {
			return {
				...state,
				isStickyCartOpen: action.payload
			};
		}

		case TOGGLE_SAVE_LOADING:
			return {
				...state,
				saveInProgress: !state.saveInProgress
			};

		case LOGOUT:
			return initialState;

		case EDIT_LINE_ITEM: {
			const { item, modifiedQuantity, modifiedTotalQuantity, resetRemoveCount } = action.payload;

			const lineQuantity = { quantity: modifiedQuantity, totalQuantity: modifiedTotalQuantity };

			const lines = {
				...state.lines,
				[item.variantShopifyId]: { ...state.lines[item.variantShopifyId], ...lineQuantity },
			};

			const linesToUpdate = { ...state.linesToUpdate, [item.id]: { ...item, ...lineQuantity } };
			return {
				...state,
				lines,
				linesToUpdate,
				pendingChanges: hasPendingChanges(state.linesToCreate, linesToUpdate, state.linesToRemove),
				totalQuantity: calculateTotalQuantity(lines),
				removeCount: resetRemoveCount ? 0 : state.removeCount + 1
			};
		}

		case REMOVE_LINE_ITEM: {
			const { item, modifiedQuantity, modifiedTotalQuantity } = action.payload;
			const lines = { ...state.lines };

			let originalItem = state.lines[item.variantShopifyId];

			// If the item is duplicated and the quantity is 0, and total quantity is greater than 0 which means there are more duplicates
			// then we need to remove the first duplicate and update the original item with the first duplicate
			if (item.isDuplicated && modifiedTotalQuantity > 0 && modifiedQuantity === 0) {
				const firstDuplicate = originalItem.duplicates.shift();
				originalItem = { ...originalItem, ...firstDuplicate, totalQuantity: modifiedTotalQuantity };
				lines[item.variantShopifyId] = originalItem;
			} else
				delete lines[item.variantShopifyId];

			const linesToRemove = { ...state.linesToRemove, [item.id]: item };

			const linesToUpdate = { ...state.linesToUpdate };
			const linesToCreate = { ...state.linesToCreate };
			delete linesToCreate[item.id];
			delete linesToUpdate[item.id];

			return {
				...state,
				lines,
				linesToCreate,
				linesToRemove,
				linesToUpdate,
				pendingChanges: hasPendingChanges(linesToCreate, linesToUpdate, linesToRemove),
				totalQuantity: calculateTotalQuantity(lines),
				removeCount: state.removeCount + 1
			};
		}

		case CLEAR_SUBSCRIPTION_CART: {
			return {
				...state,
				lines: {},
				linesToCreate: {},
				linesToUpdate: {},
				linesToRemove: {
					...state.linesToRemove,
					...Object.values(state.lines).reduce((acc, item) => {
						if (item.id)
							acc[item.id] = item;
						return acc;
					}, {})
				},
				pendingChanges: true,
				totalQuantity: 0,
				removeCount: 0,
				cartHasBeenCleared: true
			};
		}

		case CREATE_LINE_ITEM: {
			const { variantShopifyId } = action.payload;

			const itemInRemoveList = Object.values(state.linesToRemove).find(item => item.variantShopifyId === variantShopifyId);

			let lines = { ...state.lines },
				linesToCreate = { ...state.linesToCreate },
				linesToRemove = { ...state.linesToRemove },
				linesToUpdate = { ...state.linesToUpdate };

			// If the item is in the remove list, remove it from the remove list and add it back to the line items
			if (itemInRemoveList) {
				delete linesToRemove[itemInRemoveList.id];
				const item = { ...itemInRemoveList, quantity: 1, totalQuantity: 1, isDuplicated: false, duplicates: [] };
				lines = { ...lines, [variantShopifyId]: item };
				linesToUpdate = { ...linesToUpdate, [itemInRemoveList.id]: item };
			}
			else {
				lines = { ...lines, [variantShopifyId]: action.payload };
				linesToCreate = { ...linesToCreate, [variantShopifyId]: action.payload };
			}

			return {
				...state,
				linesToCreate,
				linesToRemove,
				linesToUpdate,
				lines,
				pendingChanges: hasPendingChanges(linesToCreate, linesToUpdate, linesToRemove),
				totalQuantity: calculateTotalQuantity(lines),
				removeCount: 0
			};
		}

		case UPDATE_LINE_TO_CREATE: {
			const linesToCreate = { ...state.linesToCreate };
			const variantShopifyId = action.payload.item.variantShopifyId;
			const lines = { ...state.lines };

			if (action.payload.item.quantity <= 0) {
				delete linesToCreate[variantShopifyId];
				delete lines[variantShopifyId];
			}
			else {
				linesToCreate[variantShopifyId] = lines[variantShopifyId] = action.payload.item;
			}

			return {
				...state,
				linesToCreate,
				lines,
				pendingChanges: hasPendingChanges(linesToCreate, state.linesToUpdate, state.linesToRemove),
				totalQuantity: calculateTotalQuantity(lines),
				removeCount: action.payload.resetRemoveCount ? 0 : state.removeCount + 1
			};
		}
		default:
			return state;
	}
};

export default subscriptionReducer;
